import {
    useGetNotificationsAfterQuery,
    useGetNotificationsQuery,
    useSetBookmarkMutation,
} from '../services/notificationsApi';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { LoadMore } from './LoadMore';
import { NotificationItem } from './NotificationItem';
import { NotificationList } from './NotificationList';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { dispatchMsgToParentIFrame, maxNotificationLevel, notificationCount } from '../../iFrameHelper';
import { config } from '../../config';
import { enumFromStringValue } from './enumHelper';
import { Importance, Notification } from '../services/notificationsTypes';
import unionBy from 'lodash/fp/unionBy';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getUserId } from '../../configuration/tokenHandling/tokenSlice';
import { getLatestTimestamp } from './timestampUtil';
import { motion } from 'framer-motion';
import {getLocale} from "../../configuration/lang/langSlice";

export const MAX_INITIALLY_DISPLAYED_ITEMS = 10;

export const DISPLAYED_ITEMS_INCREMENT = 5;
const POLLING_INTERVAL_MS = config.isProduction ? 45_000 : 10_000;

const NOTIFICATION_API_LIMIT = 50;

export enum PostMessageType {
    NOTIFICATIONS_POPOVER_CLOSED = 'NOTIFICATIONS_POPOVER_CLOSED',
    NOTIFICATIONS_POPOVER_OPENED = 'NOTIFICATIONS_POPOVER_OPENED',
}

export const Notifications = () => {
    const [displayedNotificationsCount, setDisplayedNotificationsCount] = useState(MAX_INITIALLY_DISPLAYED_ITEMS);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [latestTimestamp, setLatestTimestamp] = useState<string | undefined>();
    const [newNotificationsCount, setNewNotificationCount] = useState(0);
    const [notificationLevel, setNotificationLevel] = useState<Importance>('LOW');
    const [afterParam, setAfterParam] = useState<string | undefined>(undefined);
    const [hasNext, setHasNext] = useState<boolean | undefined>(undefined);
    const [skipAfterQuery, setSkipAfterQuery] = useState(true);

    const userId = useAppSelector(getUserId);
    const language = useAppSelector(getLocale);

    const {
        data: pollingData,
        isLoading,
        refetch,
    } = useGetNotificationsQuery(
        { limit: NOTIFICATION_API_LIMIT, language },
        {
            pollingInterval: POLLING_INTERVAL_MS,
        }
    );
    const [setBookmark] = useSetBookmarkMutation();

    const { data: dataAfter, isSuccess: isSuccessAfter } = useGetNotificationsAfterQuery(
        { limit: NOTIFICATION_API_LIMIT, after: afterParam, language },
        { skip: skipAfterQuery }
    );

    useEffect(() => {
        if (pollingData) {
            setNotifications((n) => {
                const mergedNotifications = unionBy('id', n)(pollingData.notifications);
                if (mergedNotifications.length > n!.length) {
                    setHasNext(pollingData.notifications?.length === NOTIFICATION_API_LIMIT);
                    setAfterParam(pollingData?.afterTag);
                }
                return mergedNotifications;
            });
        }
        setNewNotificationCount(pollingData?.notifications.filter((it) => it.state === 'NEW').length ?? 0);
        setLatestTimestamp(getLatestTimestamp(pollingData?.notifications));
        const importance = new Set(
            pollingData?.notifications.filter((it) => it.state === 'NEW')?.map((it) => it.importance)
        );
        updateImportanceLevel(importance);
    }, [pollingData]);

    const updateImportanceLevel = (importanceSet: Set<Importance>) => {
        if (importanceSet.has('HIGH')) {
            setNotificationLevel('HIGH');
        } else if (importanceSet.has('MEDIUM')) {
            setNotificationLevel('MEDIUM');
        } else if (importanceSet.size > 0) {
            setNotificationLevel('LOW');
        }
    };

    useEffect(() => {
        dispatchMsgToParentIFrame(notificationCount(newNotificationsCount));
        dispatchMsgToParentIFrame(maxNotificationLevel(notificationLevel));
    }, [displayedNotificationsCount, newNotificationsCount, notificationLevel]);

    const onLoadMore = () => {
        if (displayedNotificationsCount < (pollingData?.notifications.length ?? 10)) {
            increaseDisplayedItems();
        } else {
            setSkipAfterQuery(false);
        }
    };

    const increaseDisplayedItems = () =>
        setDisplayedNotificationsCount((prevState) => prevState + DISPLAYED_ITEMS_INCREMENT);

    useEffect(() => {
        if (isSuccessAfter && dataAfter && notifications && notifications.length > 0) {
            setNotifications(unionBy('id', dataAfter.notifications)(notifications));
            setHasNext(dataAfter.hasNext);
            setSkipAfterQuery(true);
            if (displayedNotificationsCount < notifications.length + 5) {
                increaseDisplayedItems();
            }
        }
    }, [dataAfter, isSuccessAfter, setSkipAfterQuery, setNotifications, notifications, displayedNotificationsCount]);

    useEffect(() => {
        const markAllAsRead = async () => {
            if (latestTimestamp) {
                setBookmark({ userId, readUntil: latestTimestamp }).unwrap().then(refetch);
            }
        };

        const eventHandler = async (e: MessageEvent) => {
            const postMessageType = enumFromStringValue(PostMessageType, e.data?.type);
            if (postMessageType === PostMessageType.NOTIFICATIONS_POPOVER_CLOSED) {
                await markAllAsRead();
            }
        };
        window.addEventListener('message', eventHandler);
        return () => window.removeEventListener('message', eventHandler);
    }, [latestTimestamp, refetch, setBookmark, userId]);

    if (pollingData === undefined && isLoading) {
        return (
            <div className={'margin-25'} data-testid={'loading-spinner'}>
                <Spinner isDoubleSized />
            </div>
        );
    } else if (pollingData === undefined || pollingData.notifications.length === 0 || notifications === undefined) {
        return <NoNotificationsAvailable />;
    }

    const displayedNotifications = notifications.slice(0, displayedNotificationsCount);

    return (
        <div>
            <NotificationList>
                {displayedNotifications.map((notification, idx) => (
                    <motion.div
                        key={idx}
                        animate={{ opacity: 1 }}
                        initial={{ opacity: 0 }}
                        transition={{ delay: 0.1 + idx / 25 }}
                    >
                        <NotificationItem notification={notification} />
                    </motion.div>
                ))}
            </NotificationList>
            {(notifications.length > displayedNotificationsCount || hasNext) && (
                <div className="position-fixed bottom-20 left-50pct translate-x-50pct">
                    <motion.div
                        animate={{ opacity: 1 }}
                        initial={{ opacity: 0 }}
                        transition={{ delay: 0.5 }}
                        className="bg-white shadow-smooth rounded"
                    >
                        <LoadMore onClick={onLoadMore} />
                    </motion.div>
                </div>
            )}
        </div>
    );
};

const NoNotificationsAvailable = () => (
    <NotificationList listLabel={'no-notifications'}>
        <div className={'height-50 display-flex justify-content-center align-items-center text-center'}>
            <FormattedMessage id={'intl-msg:notifications.noNotifications'} />
        </div>
    </NotificationList>
);
