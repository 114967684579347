import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config';
import { Notification, NotificationsResponse } from './notificationsTypes';
import { decodeNotificationsResponse } from './notificationsMapper';
import { accessToken } from '../../configuration/tokenHandling/accessToken';
import { AccessToken } from '../../configuration/tokenHandling/tokenSlice';

const prepareHeaders = (headers: Headers) => {
    const token = accessToken.getAccessToken() as AccessToken;
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
    }
    return headers;
};

const handleResponse = async (response: Response) => {
    if (response.ok) {
        if (response.status === 204) {
            return;
        }
        try {
            return await response.json();
        } catch (err) {
            throw new Error(`${response.status} Invalid payload: ${JSON.stringify(err)}`);
        }
    }
    if (response.status === 401) {
        throw new Error(`${response.status} Unauthorized: ${response.statusText}`);
    }
    throw new Error(`${response.status} Backend error: ${response.statusText}`);
};

export const notificationsApi = createApi({
    reducerPath: 'notificationsApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.PLATFORM_NOTIFICATION_SERVICE, prepareHeaders }),
    endpoints: (builder) => ({
        getNotifications: builder.query<
            { notifications: Notification[]; afterTag: string | undefined },
            {limit: number | undefined; language: string | undefined}
        >({
            query: ({limit = 1000, language}) => {
                const urlSearchParams = new URLSearchParams();
                urlSearchParams.set('limit', limit.toString());
                if(language) urlSearchParams.set('language', language);
                return {
                    url: `/notifications?${urlSearchParams}`,
                    responseHandler: (response) => handleResponse(response),
                };
            },
            transformResponse: (rawResult: NotificationsResponse) => decodeNotificationsResponse(rawResult),
        }),
        getNotificationsAfter: builder.query<
            { notifications: Notification[]; afterTag: string | undefined; hasNext: boolean },
            { limit: undefined | number; after: string | undefined; language: string | undefined }
        >({
            query: ({ limit = 1000, after, language }) => {
                const urlSearchParams = new URLSearchParams();
                urlSearchParams.set('limit', limit.toString());
                if(after) urlSearchParams.set('after', after);
                if(language) urlSearchParams.set('language', language);
                return {
                    url: `/notifications?${urlSearchParams}`,
                    responseHandler: async (response: Response) => handleResponse(response),
                };
            },
            transformResponse: (rawResult: NotificationsResponse, meta, arg) =>
                decodeAndAddHasNextFlag(rawResult, arg.after),
        }),
        setBookmark: builder.mutation<void, { userId: string; readUntil: string }>({
            query: ({ userId, readUntil }) => ({
                url: `/bookmarks/${userId}`,
                method: 'PUT',
                body: {
                    read_until: readUntil,
                },
                responseHandler: async (response: Response) => handleResponse(response),
            }),
        }),
    }),
});

const decodeAndAddHasNextFlag = (rawResult: NotificationsResponse, after: string | undefined) => {
    const decodedResponse = decodeNotificationsResponse(rawResult);
    const hasNext = decodedResponse.afterTag !== after;
    return {
        ...decodedResponse,
        hasNext,
    };
};

export const { useGetNotificationsQuery, useGetNotificationsAfterQuery, useSetBookmarkMutation } = notificationsApi;
