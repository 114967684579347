import { FormattedMessage } from 'react-intl';

export const LoadMore = ({ onClick }: { onClick: () => void }) => (
    <div className={'display-flex justify-content-center width-100pct'}>
        <button
            type="button"
            className="btn btn-default btn-outline border-none hover-bg-lighter hover-text-color-highlight-dark"
            data-testid={'load-more-btn'}
            onClick={onClick}
        >
            <span className="rioglyph rioglyph-arrow-down" />
            <span>
                <FormattedMessage id={'intl-msg:notifications.loadMore'} />
            </span>
        </button>
    </div>
);
