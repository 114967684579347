import { IntlShape, useIntl } from 'react-intl';

export const FormattedTimestamp = ({ timestamp }: { timestamp: string }) => {
    const intl = useIntl();
    return <>{mapTimestamp(timestamp, intl)}</>;
};

const mapTimestamp = (rawTimestamp: string, intl: IntlShape) => {
    const timestamp = new Date(rawTimestamp);

    if (isDateBeforeYesterday(timestamp)) {
        return `${intl.formatDate(timestamp)}, ${intl.formatTime(timestamp)}`;
    } else if (isDateBeforeToday(timestamp)) {
        return `${intl.formatMessage({ id: 'intl-msg:notifications.yesterday' })}, ${intl.formatTime(timestamp)}`;
    } else {
        return `${intl.formatMessage({ id: 'intl-msg:notifications.today' })}, ${intl.formatTime(timestamp)}`;
    }
};

const isDateBeforeYesterday = (date: Date) => {
    const tmpDate = new Date();
    tmpDate.setDate(tmpDate.getDate() - 1);
    return new Date(date.toDateString()) < new Date(tmpDate.toDateString());
};

const isDateBeforeToday = (date: Date) => {
    return new Date(date.toDateString()) < new Date(new Date().toDateString());
};
