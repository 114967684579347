import { Notification } from '../services/notificationsTypes';

export const getLatestTimestamp = (notifications: Notification[] | undefined): string | undefined => {
    const timestamps = notifications
        ?.filter((it) => it.state === 'NEW')
        .map((it) => it.createdAt)
        .sort((a, b) => {
            // sort descending
            return a > b ? -1 : a < b ? 1 : 0;
        });

    if (timestamps === undefined || timestamps.length === 0) {
        return undefined;
    } else {
        return timestamps[0];
    }
};
