import { decode } from './iotsDecoder';
import { Importance, Notification, notificationsResponse, NotificationsResponse, State } from './notificationsTypes';
import qs from 'qs';

const determineState = (rawState: String): State => {
    switch (rawState) {
        case 'NEW':
            return 'NEW';
        case 'READ':
            return 'READ';
        default:
            return 'UNKNOWN';
    }
};

export const determineImportance = (rawImportance: String | undefined): Importance => {
    switch (rawImportance) {
        case 'LOW':
            return 'LOW';
        case 'MEDIUM':
            return 'MEDIUM';
        case 'HIGH':
            return 'HIGH';
        default:
            return 'LOW';
    }
};

export interface MappedNotifications {
    notifications: Notification[];
    afterTag: string | undefined;
}

export const decodeNotificationsResponse = (parsedObject: NotificationsResponse): MappedNotifications => {
    if (parsedObject.items.length === 0) {
        return { notifications: [], afterTag: undefined };
    }
    const decoded = decode(parsedObject, notificationsResponse);
    const nextLink = decoded['_links'].next?.href;
    const queryString = nextLink ? nextLink.substring(nextLink.indexOf('?') + 1) : '';
    const { after } = qs.parse(queryString);
    return {
        notifications: decoded.items
            .map((it) => ({
                createdAt: it.created_at,
                id: it.id,
                importance: determineImportance(it.importance),
                link: it.link,
                message: it.content.message,
                state: determineState(it.state.value),
                title: it.content.title,
            }))
            .filter((it) => it.state !== 'UNKNOWN'),
        afterTag: after ? (after as string) : undefined,
    };
};
