import { extractLanguage } from './lang/lang';
import { configureFetchDisplayMessages } from './lang/services';
import {
    configureMockUserManager,
    configureUserManager,
    createUserManager,
    enableOidcDebugLogging,
    SessionRenewedResult,
} from './login/login';
import { accessToken } from './tokenHandling/accessToken';
import { trace } from './setup/trace';
import { attemptInitialSignIn } from './setup/oauth';
import { config } from '../config';
import { reportErrorToSentry } from './setup/sentry';
import { store } from './setup/store';
import { accessTokenStored, idTokenStored } from './tokenHandling/tokenSlice';
import { userProfileObtained, userSessionExpired, userSessionRenewed } from './login/loginSlice';
import { getLocale } from './lang/langSlice';

export interface OAuthConfig {
    onSessionExpired: Function;
    onSessionRenewed: Function;
}

export interface Tokens {
    accessToken: string | null;
    idToken: string | null;
}

export const main = async (renderApp: Function) => {
    const fetchDisplayMessages = configureFetchDisplayMessages(store);

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html && lang && html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    const oauthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result: SessionRenewedResult) => {
            trace('index.onTokenRenewed', result);

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));

            store.dispatch(userSessionRenewed());

            // You will need to get the user language yourself then
            // you may fetch the suitable messages. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            fetchDisplayMessages(result.locale);
        },
    } as OAuthConfig;

    // enables mocking of authentication in production
    const testAccessToken = sessionStorage && sessionStorage.getItem(`${config.login.clientId}_test_access_token`);
    // enables mocking of authentication in production
    const testIdToken = sessionStorage && sessionStorage.getItem(`${config.login.clientId}_test_id_token`);
    const tokens = { accessToken: testAccessToken, idToken: testIdToken };

    const isAllowedToMockAuth = import.meta.env.DEV;
    const userManager =
        (isAllowedToMockAuth && config.login.mockAuthorization) || testAccessToken
            ? configureMockUserManager(oauthConfig, tokens)
            : configureUserManager(oauthConfig, createUserManager());

    const signinSilent = userManager.signinSilent.bind(userManager);
    document.addEventListener('EVENT_USER_LANGUAGE_CHANGED', () => signinSilent());
    document.addEventListener('EVENT_USER_PROFILE_CHANGED', () => signinSilent());

    const oidcLogLevel = localStorage.getItem('oidcLogLevel');
    if (oidcLogLevel === 'debug') {
        enableOidcDebugLogging();
    }

    try {
        await userManager.clearStaleState();
        await attemptInitialSignIn(userManager);
        renderApp();
    } catch (error) {
        trace('could not start application', error);
        reportErrorToSentry(error);
    }
};
