import packageJson from '../package.json';

export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        PLATFORM_NOTIFICATION_SERVICE: string | undefined;
    };
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        mockTenant: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    serviceVersion: string;
    serviceEnvironment: string;
    logoutUri: string | undefined;
    sentryToken: string | undefined;
    sentryModuleName: string;
    isProduction: boolean;
    enableMockServer: boolean;
}

const getBoolEnvValue = (envValue: string): boolean => {
    return typeof import.meta.env[envValue] !== 'undefined' && import.meta.env[envValue] === 'true';
};

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        PLATFORM_NOTIFICATION_SERVICE: import.meta.env.VITE_PLATFORM_NOTIFICATION_SERVICE,
    },
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '7a8fc928-b72a-4bcd-b6f8-c52bc201aa84',
        oauthScope: [
            'openid',
            'profile',
            'email',
            'phone',
            'platform.notifications.read',
            'platform.notifications-state.write',
        ],
        mockAuthorization: getBoolEnvValue('VITE_LOGIN_MOCK_AUTHORIZATION'),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        mockTenant: import.meta.env.VITE_LOGIN_MOCK_TENANT,
        preventRedirect: getBoolEnvValue('VITE_LOGIN_PREVENT_REDIRECT'),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    sentryModuleName: 'platformNotifications',
    isProduction: !import.meta.env.DEV,
    enableMockServer: import.meta.env.DEV,
};
