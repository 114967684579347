import * as t from 'io-ts';

const notification = t.type({
    id: t.string,
    content: t.type({
        message: t.string,
        title: t.string,
    }),
    recipient: t.type({
        identifier: t.string,
        type: t.string,
    }),
    state: t.type({
        value: t.string,
    }),
    importance: t.union([t.string, t.undefined]),
    link: t.union([t.string, t.undefined]),
    created_at: t.string,
    language: t.union([t.string, t.undefined]),
});

const notifications = t.array(notification);

const link = t.type({
    href: t.string,
});

const paginationLinks = t.type({
    next: t.union([link, t.undefined]),
    self: link,
});

export const notificationsResponse = t.type({
    items: notifications,
    _links: paginationLinks,
});

export type ApiNotification = t.TypeOf<typeof notification>;

export type NotificationsResponse = t.TypeOf<typeof notificationsResponse>;

export type State = 'NEW' | 'READ' | 'UNKNOWN';

export type Importance = 'LOW' | 'MEDIUM' | 'HIGH';

export interface Notification {
    createdAt: string;
    id: string;
    message: string;
    state: State;
    importance: Importance;
    link: string | undefined;
    title: string;
}
