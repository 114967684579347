import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { Notification } from '../services/notificationsTypes';
import { ImportanceGlyph } from './ImportanceGlyph';
import { FormattedTimestamp } from './FormattedTimestamp';
import classNames from 'classnames';
import { PostMessageType } from './Notifications';

export const NotificationItem = ({ notification }: { notification: Notification }) => {
    const onClickHandler =
        notification.link === undefined
            ? () => {}
            : () => {
                  window.postMessage({ type: PostMessageType.NOTIFICATIONS_POPOVER_CLOSED }, '*');
                  window.top!.location.href = notification.link!;
              };

    const notificationTitleClasses = classNames(
        'display-flex justify-content-between gap-10 align-items-center width-100pct'
    );
    const notificationTitleTextClasses = classNames('display-flex align-items-center gap-5');
    const notificationDateClasses = classNames(
        `text-size-12 ${notification.state === 'NEW' ? 'text-color-primary' : 'text-color-gray'}`
    );
    const notificationTextClasses = classNames(`
        position-relative padding-bottom-10 padding-x-10 line-height-125rel
        ${
            notification.link === undefined
                ? 'cursor-default'
                : 'cursor-pointer hover-text-decoration-underline underline-offset-3'
        }
    `);
    const expanderHeaderClasses = classNames('padding-x-10');
    const expanderTitleClasses = classNames('display-flex justify-content-between width-100pct');
    const expanderBodyClasses = classNames('padding-0');
    const expanderClasses = classNames('border border-bottom-only rounded-none margin-bottom-0');

    return (
        <ExpanderPanel
            title={
                <div className={notificationTitleClasses}>
                    <div className={notificationTitleTextClasses}>
                        {notification.importance !== 'LOW' && (
                            <ImportanceGlyph importance={notification.importance} state={notification.state} />
                        )}
                        <span className="text-medium">{notification.title}</span>
                    </div>
                    <span className={notificationDateClasses}>
                        <FormattedTimestamp timestamp={notification.createdAt} />
                        {/* {notification.state === 'NEW' && (
                            <span className="margin-left-5 text-uppercase">
                                <FormattedMessage id={'intl-msg:notifications.new'} />
                            </span>
                        )} */}
                    </span>
                </div>
            }
            bsStyle="blank"
            headerClassName={expanderHeaderClasses}
            titleClassName={expanderTitleClasses}
            bodyClassName={expanderBodyClasses}
            className={expanderClasses}
            open={notification.state === 'NEW'}
            data-testid={'notification-list-item'}
        >
            <div className={notificationTextClasses} onClick={onClickHandler}>
                <div data-testid={'notification-message'}>{notification.message}</div>
            </div>
        </ExpanderPanel>
    );
};
