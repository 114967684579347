import { IntlProvider } from 'react-intl';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { DEFAULT_LOCALE } from '../../configuration/lang/lang';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../../configuration/lang/langSlice';
import { Notifications } from '../notifications/Notifications';

const App = () => {
    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);

    if (!displayMessages || !userLocale) {
        return null;
    }

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <ApplicationLayout className={'PlatformNotifications'}>
                <Notifications />
            </ApplicationLayout>
        </IntlProvider>
    );
};

export default App;
